export const getFile = url => {
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url,
            responseType: "arraybuffer"
        })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error.toString());
            });
    });
};
import axios from "axios";
export const downloadMinxin = {
    data() {
        return {};
    },
    computed: {},
    methods: {
        download(row) {
            this.$Spin.show();
            getFile(row.src).then(data => {
                let url = URL.createObjectURL(new Blob([data.data]));
                let a = document.createElement("a");
                document.body.appendChild(a); //此处增加了将创建的添加到body当中
                a.href = url;
                console.log(row.name);
                a.download = row.name;
                a.target = "_blank";
                a.click();
                a.remove(); //将a标签移除
                this.$Spin.hide();
            });
        }
    }
};
